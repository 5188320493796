// -- The HTML Serializer
// This function will be used to modify the way that a Rich Text or Title field is rendered.

import React, { ReactNode } from 'react';
import { Link as PrismicLink } from 'prismic-reactjs';
import { Elements, NODE_TYPES } from 'prismic-richtext';
import { Link } from 'gatsby';
import { gastbyLinkResolver } from './utils';

export default function(
  type: NODE_TYPES,
  element: any,
  content: any,
  children: any,
  index: number,
) {
  // Generate links to Prismic Documents as <Link> components
  if (type === Elements.hyperlink) {
    let result: ReactNode = null;
    const url = PrismicLink.url(element.data, gastbyLinkResolver);

    if (element.data.link_type === 'Document') {
      result = (
        <Link to={url} key={index}>
          {content}
        </Link>
      );
    } else {
      const target = element.data.target
        ? { target: element.data.target, rel: 'noopener' }
        : {};
      result = (
        <a href={url} {...target} key={index}>
          {content}
        </a>
      );
    }
    return result;
  }

  // If the image is also a link to a Prismic Document, it will return a <Link> component
  if (type === Elements.image) {
    let result = <img src={element.url} alt={element.alt || ''} />;

    if (element.linkTo) {
      const url = PrismicLink.url(element.linkTo, gastbyLinkResolver);

      if (element.linkTo.link_type === 'Document') {
        result = (
          <Link to={url} key={index}>
            {result}
          </Link>
        );
      } else {
        const target = element.linkTo.target
          ? { target: element.linkTo.target, rel: 'noopener' }
          : {};
        result = (
          <a href={url} {...target}>
            {result}
          </a>
        );
      }
    }
    const wrapperClassList = [element.label || '', 'block-img'];
    result = (
      <p className={wrapperClassList.join(' ')} key={index}>
        {result}
      </p>
    );
    return result;
  }

  // Return null to stick with the default behavior for everything else
  return null;
}
