import React from 'react';
import { navigate } from 'gatsby';

const RelatedBlogs = ({ currentPage }) => {
  const blogData = [
    {
      name: 'plastic-free-packaging',
      title: 'Plastic free packaging?',
      text: 'Feed My Furb...',
      date: 'Jan. 16, 2020, 2:41 p.m.',
    },
    {
      name: '6-reasons-try-feed-my-furbaby',
      title: '6 Reasons To Try Feed My Furbaby',
      text: "We're ...",
      date: 'Oct. 25, 2019, 12:06 p.m',
    },
    {
      name: 'feeding-new-puppy-easy-guide',
      title: 'Feeding A New Puppy: An Easy Guide',
      text: 'What Should ...',
      date: 'Oct. 10, 2019, 1:57 p.m.',
    },

    {
      name: 'dog-biscuit-size-realted-my-dogs-dental-health',
      title: 'Is biscuit size related to my dogs dental health?',
      text: 'The short an...',
      date: 'Aug. 21, 2019, 10:37 a.m.',
    },
    {
      name: 'environmental-pawprint-pet-food',
      title: 'The environmental pawprint of pet food',
      text: 'Have you con...',
      date: 'May 30, 2019, 9:06 a.m.',
    },
  ];

  const onBlockClicked = path => {
    navigate('/blog/' + path);
  };

  return (
    <div className="blog-rt-wrap">
      <h4 className="blog-rt-hdr">Related blogs</h4>
      <div className="blog-rt-contents blog-rt-blogs">
        {blogData.map(item => {
          if (item.name !== currentPage) {
            return (
              <div
                key={item.name}
                className="rel-blog-item"
                onClick={() => onBlockClicked(item.name)}
              >
                <a>
                  <h5>{item.title}</h5>
                  <p className="rel-blg-sub">{item.text}</p>
                  {/* <div className="rel-blg-pub">{item.date}</div> */}
                </a>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default RelatedBlogs;
