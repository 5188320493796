import React from 'react';

const RelatedTags = ({ tags }) => {
  const tagsArr = tags ? tags.split(',') : [];
  return (
    <div className="blog-rt-wrap">
      <h4 className="blog-rt-hdr">Related tags</h4>
      <div className="blog-rt-contents blog-rt-tags">
        {tagsArr.map(item => {
          return <span key={item}>{item}</span>;
        })}
      </div>
    </div>
  );
};

export default RelatedTags;
