import React from 'react';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../htmlSerializer';
import { gastbyLinkResolver as linkResolver } from '../../utils';
import { BlogPostSlice } from 'services/models/blog';

export default ({ slice }: { slice: BlogPostSlice }) => (
  <div className="post-text container">
    <div>
      {RichText.render(slice.primary.text.raw, linkResolver, htmlSerializer)}
    </div>
  </div>
);
