import React from 'react';
import moment from 'moment';
import { graphql, navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { ImageCaption, Text } from '../components/PrismicSlices';
import MainLayout from '../layouts/mainLayout';
import RelatedTags from 'components/Blog/RelatedTags';
import RelatedBlogs from 'components/Blog/RelatedBlogs';
import {
  BlogPostList,
  BlogPostSlice,
  PostItemType,
} from 'services/models/blog';
import styles from './post.module.scss';
import Banner from 'components/Banner';
import { convertToTitleCase } from '../../src/utils';

// Query for the Blog Post content in Prismic
export const query = graphql`
  query BlogPostQuery {
    allPrismicBlogPost {
      edges {
        node {
          id
          uid
          data {
            title {
              text
              html
              raw
            }
            meta_title
            meta_description
            timestamp
            previewtext
            tags
            body {
              ... on PrismicBlogPostBodyText {
                primary {
                  text {
                    text
                    html
                    raw
                  }
                }
              }
              ... on PrismicBlogPostBodyImageWithCaption {
                primary {
                  header_im {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Sort and display the different slice options
const PostSlices = ({ slices }: { slices: BlogPostSlice[] }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.__typename) {
        case 'PrismicBlogPostBodyText':
          slice.primary.text.raw = (slice?.primary?.text?.raw || []).map(it => {
            const reworkTags = ['heading2', 'heading1'];
            if (reworkTags.includes(it.type)) return {...it,text:convertToTitleCase(it.text||"")};
            return it;
          });
          return (
            <div key={index} className="blog-contents-main">
              {<Text slice={slice} />}
            </div>
          );
        case 'PrismicBlogPostBodyImageWithCaption':
          return (
            <div key={index} className={styles.imageContentsMain}>
              {<ImageCaption slice={slice} />}
            </div>
          );

        default:
          return;
      }
    })();
    return res;
  });
};

// Display the title, date, and content of the Post
const PostBody = ({
  postData: { data: blogPost, uid },
}: {
  postData: { data: PostItemType; uid: string };
}) => {
  const titled = blogPost.title.text?.length !== 0;

  return (
    <div className={styles.blogTxtContent}>
      <div className="blog-txt-main">
        <div className="blog-txt-hdr">
          <h2 data-wio-id={uid}>
            {convertToTitleCase(titled ? RichText.asText(blogPost.title.raw) : 'Untitled')}
          </h2>
          {/* {blogPost.timestamp && (
            <div className="blog-txt-hdr-sub">
              Published on {moment(blogPost.timestamp).format('MMM DD, YYYY')}
            </div>
          )} */}
        </div>
        <PostSlices slices={blogPost.body} />
        <Banner />
      </div>
    </div>
  );
};

export interface EdgeNodeDataType {
  uid: string;
  body: BlogPostSlice;
  tags: string;
  title: {
    raw: [];
    text: string;
  };
  timestamp: string;
}

export interface EdgeType {
  uid: string;
  node: {
    id: string;
    uid: string;
    data: EdgeNodeDataType;
  };
}

export default (props: {
  pageContext: { id: string };
  data: {
    allPrismicBlogPost: { edges: BlogPostList[] };
  };
}) => {
  const {
    pageContext,
    data: {
      allPrismicBlogPost: { edges },
    },
  } = props;
  const docIndex = edges.findIndex(item => item.node.id === pageContext.id);
  const doc = edges[docIndex];

  if (!doc) return null;
  const { meta_description, meta_title } = doc.node.data;
  return (
    <MainLayout withTitle title={meta_title} description={meta_description}>
      <div className="ingr-col-wrapper no-mgn-bot blog-wrap-or">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="blog-post-wrapper">
              <div className="blog-post-txt">
                <div className="row">
                  <div className="col-sm-9">
                    <PostBody postData={doc.node} />
                  </div>
                  <div className="col-sm-3">
                    <div className="blog-txt-rt">
                      <RelatedTags tags={doc.node.data.tags} />
                      <RelatedBlogs currentPage={doc.node.uid} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
