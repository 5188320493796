import React, { Fragment } from 'react';
import { RichText } from 'prismic-reactjs';
import { BlogPostSlice } from 'services/models/blog';

// Default Image
const DefaultImage = ({ slice }: { slice: BlogPostSlice }) => (
  <div className="post-image container">
    <figcaption className="block-img">
      <img src={slice.primary.header_im.url} alt={slice.primary.header_im.alt} />
      {slice.primary.caption &&
      RichText.asText(slice.primary.caption) !== '' ? (
        <figcaption className="image-label">
          {RichText.asText(slice.primary.caption)}
        </figcaption>
      ) : null}
    </figcaption>
  </div>
);

// Emphasized Image
const EmphasizedImage = ({ slice }: { slice: BlogPostSlice }) => (
  <div className="post-image container">
    <figcaption className="block-img emphasized">
      <img src={slice.primary.header_im.url} alt={slice.primary.header_im.alt} />
      {slice.primary.caption &&
      RichText.asText(slice.primary.caption) !== '' ? (
        <figcaption className="image-label">
          {RichText.asText(slice.primary.caption)}
        </figcaption>
      ) : null}
    </figcaption>
  </div>
);

// Full Width Image
const FullWidthImage = ({ slice }: { slice: BlogPostSlice }) => (
  <div
    className="post-image full-width-image"
    style={{ backgroundImage: 'url(' + slice.primary.header_im.url + ')' }}
  >
    <div className="wrapper">
      {slice.primary.caption &&
      RichText.asText(slice.primary.caption) !== '' ? (
        <span className="image-label">
          {RichText.asText(slice.primary.caption)}
        </span>
      ) : null}
    </div>
  </div>
);

// Function to determine the image type
const renderSwitch = function(slice: BlogPostSlice) {
  switch (slice.label) {
    case 'image-full-width':
      return <FullWidthImage slice={slice} />;
    case 'emphasized':
      return <EmphasizedImage slice={slice} />;
    default:
      return <DefaultImage slice={slice} />;
  }
};

export default ({ slice }: { slice: BlogPostSlice }) => {
  return <Fragment>{renderSwitch(slice)}</Fragment>;
};
