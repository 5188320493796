import React from 'react';
import styles from './style.module.scss';
import { navigate } from 'gatsby';
import { Button } from 'react-bootstrap';

type Props = {};

const Banner = (props: Props) => {
  return (
    <div className={styles.backgroundbg}>
      <div className={styles.ContentContainer}>
        <span>
          <span>
            Get started today and give your furbaby the nutrition they deserve!
          </span>
          <br />
          <span className={styles.ActionContainer}>
            <Button
              className="btn-def btn-small"
              onClick={() => {
                navigate('/getstarted/');
              }}
            >
              Get Started
            </Button>
            <span className={styles.Seperator}>or</span>
            <Button
              className="btn-def btn-small"
              onClick={() => {
                navigate('/products/');
              }}
            >
              Shop Now
            </Button>
          </span>
        </span>
      </div>
    </div>
  );
};

export default Banner;
